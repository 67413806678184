<template>
  <v-bottom-sheet
    class="font font-weight-medium"
    inset
    v-model="state"
    persistent
  >
    <v-card tile>
      <v-progress-linear
        :value="100"
        color="primary"
        class="my-0"
        height="3"
      ></v-progress-linear>
      <div v-if="details" class="d-flex flex-row flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
          <span class="font-weight-bold font-size-md font mx-1 px-3 pt-3"
            >Order Reference</span
          >
          <span class="font-weight-bold text-sm font mx-1 px-3 pb-1">
            {{ details.orderLineItemId.orderReference }}
          </span>
          <span class="font-weight-bold font-size-md font mx-1 px-3"
            >Invoice Number</span
          >
          <span class="font-weight-bold text-sm font mx-1 px-3 pb-1">
            {{ details.orderLineItemId.invoiceNumber }}
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <span class="font-weight-bold font-size-md font mx-1 px-3 pt-3"
            >Vehicle Details</span
          >
          <template
            v-if="details.orderLineItemId && details.orderLineItemId.carId"
          >
            <span class="font-weight-bold font-size-sm font mx-1 px-3">
              REG. No. : {{ details.orderLineItemId.carId.registrationNumber }}
            </span>
            <span class="font-weight-bold font-size-sm font mx-1 px-3 pb-1">
              MODEL : {{ details.orderLineItemId.carId.model }}
            </span>
          </template>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <span class="font-weight-bold font-size-md font mx-1 px-3 pt-3"
            >Order Date</span
          >
          <span class="font-weight-bold text-sm font mx-1 px-3 pb-3">
            {{ details.orderLineItemId.date | timestamp("Do MMMM, YYYY") }}
          </span>
        </div>
        <v-btn @click.stop="close" small right top absolute fab color="primary">
          <i class="material-icons-outlined">close</i>
        </v-btn>
      </div>
      <div class="d-flex flex-row flex-grow-1 mt-3">
        <span class="px-4 font font-weight-bold font-size-md text-uppercase">
          Confirm Delivery
        </span>
      </div>
      <div v-if="details" class="d-flex flex-row flex-grow-1 mx-2 mt-2 px-1">
        <ValidationObserver
          v-slot="{ handleSubmit }"
          class="d-flex flex-row flex-grow-1"
          tag="div"
        >
          <form
            @submit.prevent="handleSubmit(confirmDeliveries)"
            style="width: 100%"
          >
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex flex-row flex-grow-1">
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-column flex-grow-1 ma-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    label="Product"
                    class="font font-weight-bold font-size-md"
                    v-model="details.productId.name"
                    :error-messages="errors[0]"
                    readonly
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-column flex-grow-1 ma-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    label="Quantity"
                    class="font font-weight-bold font-size-md"
                    v-model="details.quantity"
                    :error-messages="errors[0]"
                    readonly
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-column flex-grow-1 ma-1"
                  v-slot="{ errors }"
                  rules="required|numeric"
                >
                  <v-text-field
                    outlined
                    v-model="confirmedQuantity"
                    dense
                    label="Confirm Quantity"
                    class="font font-weight-bold font-size-md"
                    :error="details.quantity < parseInt(confirmedQuantity)"
                    :error-messages="errors[0]"
                    clearable
                  />
                </ValidationProvider>
              </div>
              <div class="d-flex flex-row flex-grow-1">
                <v-textarea
                  label="Comment"
                  v-model="comment"
                  dense
                  outlined
                  class="font font-weight-medium px-1"
                />
              </div>
            </div>
            <v-alert
              type="error"
              dense
              v-if="details.quantity > parseInt(confirmedQuantity)"
            >
              <span class="font font-weight-medium font-size-md"
                >Seems your confirmation quantity is less than what you're
                suppose to receive. Contact manager to rectify it.</span
              >
            </v-alert>
            <div
              class="d-flex flex-row mr-2 mt-2 mb-5"
              style="justify-content: flex-end;"
            >
              <v-btn
                color="primary"
                type="submit"
                :disabled="
                  loading ||
                    details.quantity < parseInt(confirmedQuantity) ||
                    details.quantity > parseInt(confirmedQuantity)
                "
                :loading="loading"
              >
                <span class="font font-weight-medium">Confirm Delivery</span>
              </v-btn>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { timestamp } from "../../../filters/timestamp";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field must be number"
});
export default {
  name: "ProductDeliveryConfirmation",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    loading: Boolean,
    state: Boolean,
    details: {
      type: Object
    }
  },
  data() {
    return {
      confirmedQuantity: "",
      comment: ""
    };
  },
  filters: {
    timestamp
  },
  methods: {
    close() {
      this.$emit("actions", "details");
    },
    confirmDeliveries() {
      let payload = {
        deliveryId: this.details.id,
        body: {
          productId: this.details.productId.id,
          branchId: this.details.branchId.id,
          quantity: parseInt(this.confirmedQuantity),
          comment: this.comment
        }
      };
      this.$emit("confirmDeliveries", payload);
      console.log(payload);
    }
  }
};
</script>
